import {
  adjustments,
  layoutStates,
  openTextBrands,
  pageStates,
  paymentTypes,
  purchaseErrors,
  purchaseErrorsNotifications,
  terms,
} from "@/components/Buy/BuyEnums";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { useUserStore } from "@/stores/user";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { formatDateString } from "@/common/dateFormat";
import {
  DEFAULT_QUANTITY,
  DEFAULT_SKU,
  DEFAULT_TERM_LENGTH_MONTHS,
  EMPTY_GUID,
  SAFE_RENEWAL_WINDOW_DAYS,
  SUBSCRIPTION_LAST_ALLSTATE_DAY,
  URLS,
  buyFlowRouteName,
} from "@/define";
import { NotificationLookup, NotificationType, useNotificationsStore } from "@/stores/notifications";
import { LocationQuery } from "vue-router";
import { useUnauthenticatedBuyFlowStore } from "@/stores/unauthorizedBuyflow";
import { unifiedApi } from "@/common";
import {
  ICartItem,
  IPaymentMethod,
  IPayPalApprovalResponse,
  IPayPalShippingAddressInfo,
  IProductCatalog,
  IShoppingCart,
} from "@/common/api/unifiedPortal/interfaces";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import {
  IBeginShoppingCartRequest,
  ISetupCartRequest,
  ISubscriptionResult,
  iGroupedProducts,
} from "@/components/Buy/Interfaces";
import { usePaymentPageStore } from "@/stores/paymentPage";
import { pageStates as paymentPageStates } from "@/components/Buy/Payment/PaymentConfigs";
import { getDeviceInfo, getUserDetailsFromStore } from "@/common/userDetails";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { logEvent } from "@/common/logger";
import router from "@/routes";
import { cartUpdate, googleTagCartEvents } from "@/common/googleTagEvents";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import { useSecurityStore } from "@/stores/security";
import { getRedirectToLoginURL, getRedirectToLoginURLWithEmail } from "@/common/loginRedirect";
import {
  isFirstNameValid,
  isLastNameValid,
  isAddress1Valid,
  isZipValid,
  isStateValid,
  isCityValid,
} from "./Payment/ShippingFormValidation";
import {
  generateInterstitialReturnPortalDestination,
  processSubscriptionDataForDisplay,
} from "@/common/subscriptionHelper";
import { getUrl } from "@/common/getUrl";
import { allstateLCNs, convertWebrootSku, webrootCDSku, webrootOnlyAllstateSkus } from "@/common/webrootProductName";
import { ExpirationState } from "@/globalEnums";
import { usePayPalStore } from "@/stores/payPal";

export const adjustmentsOrder = [
  adjustments.BonusMonthsPromo,
  adjustments.PromoCodeAmt,
  adjustments.PriorSubscriptionCredit,
  adjustments.TrialDaysRemaining,
  adjustments.LicenseDaysRemaining,
  adjustments.Tax,
];

export const groupedProducts: iGroupedProducts = {
  //UltimateDuo is used in a unit test. If this is updated you will need to update the test
  UltimateDuo: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 3 },
    { sku: "WebrootSecureVPN", months: 12, includedUnits: 3 },
  ],
  DUOAVWIFI: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 1 },
    { sku: "WebrootSecureVPN", months: 12, includedUnits: 3 },
  ],
  DUOPLUSWIFI: [
    { sku: "WebrootPlus", months: 12, includedUnits: 3 },
    { sku: "WebrootSecureVPN", months: 12, includedUnits: 3 },
  ],
  DUOCOMPLETEWIFI: [
    { sku: "WebrootComplete", months: 12, includedUnits: 5 },
    { sku: "WebrootSecureVPN", months: 12, includedUnits: 5 },
  ],
  DUOAVPREMIUM: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 5 },
    { sku: "WebrootPremium", months: 12, includedUnits: 5 },
  ],
  DUOFAMILYWIFI: [
    { sku: "WebrootPremiumFamily", months: 12, includedUnits: 10, includedIdentities: 10 },
    { sku: "WebrootSecureVPN", months: 12, includedUnits: 5 },
  ],
  DUOPREMIUMWIFI: [
    { sku: "WebrootPremium", months: 12, includedUnits: 5 },
    { sku: "WebrootSecureVPN", months: 12, includedUnits: 5 },
  ],
  DUOAVAIP: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 1 },
    { sku: "AllstateIdentityProtectionBasic", months: 12, includedIdentities: 1 },
  ],
  DUOAVAIPWIFI: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 1 },
    { sku: "AllstateIdentityProtectionBasic", months: 12, includedIdentities: 1 },
    { sku: "WebrootSecureVPN", months: 12, includedUnits: 3 },
  ],
};

export function removeStars(v: string | undefined) {
  return v ? v.replaceAll("*", "") : "";
}

//Only formats in USD for now. May need to update to other currencies in the future
export function formatMoney(amount = 0) {
  return amount.toLocaleString("en-US", { style: "currency", currency: "USD" });
}

export function getTermSuffix(months: number | undefined) {
  if (!months) {
    return "";
  }

  let suffix = terms.monthToMonth;
  if (months === 12) {
    suffix = terms.year;
  }
  if (months > 12) {
    suffix = terms.years;
  }

  return `Common.${suffix}`;
}

export function setupCartForGroupedProducts(sku: string) {
  //Get out if its not a grouped product sku
  if (sku && !groupedProducts[sku]) {
    return;
  }
  const buyFlowStore = useBuyFlowStore();
  const groupedProduct = groupedProducts[sku];
  let count = 1;

  for (const item of groupedProduct) {
    const ratePlanId = buyFlowStore.getRatePlanIdBySku(
      item.sku,
      item.months,
      item?.includedUnits || item?.includedIdentities
    );

    if (count === 1) {
      const beginShoppingCartRequest = {
        sku: item.sku,
        ratePlanId: ratePlanId,
        quantity: 1,
        autoRenew: true,
      };

      buyFlowStore.beginShoppingCart(beginShoppingCartRequest);
    } else {
      const cartItem = {
        sku: item.sku,
        ratePlanId: ratePlanId,
        quantity: 1,
        autoRenew: true,
      } as ICartItem;
      buyFlowStore.cart?.items.push(cartItem);
    }

    count += 1;
  }
}

export async function setupCartNewUser(request: ISetupCartRequest) {
  const buyFlowStore = useBuyFlowStore();
  let beginShoppingCartRequest: IBeginShoppingCartRequest;

  //If the sku is in the config for grouped products add all products to the cart
  if (request.sku && groupedProducts[request.sku]) {
    setupCartForGroupedProducts(request.sku);
  } else if (request.ratePlanId) {
    const sku = buyFlowStore.getSkuByRatePlan(request.ratePlanId);
    beginShoppingCartRequest = {
      sku: sku,
      ratePlanId: request.ratePlanId,
      quantity: request.quantity,
      deviceId: request.deviceId,
    };
    buyFlowStore.beginShoppingCart(beginShoppingCartRequest);
    buyFlowStore.setRecommendSkuFromCurrent(sku);
  } else {
    beginShoppingCartRequest = {
      sku: request.sku || DEFAULT_SKU,
      ratePlanId: buyFlowStore.getRatePlanIdBySku(
        request.sku || DEFAULT_SKU,
        request.subscriptionMonths || DEFAULT_TERM_LENGTH_MONTHS,
        request.includedUnits
      ),
      quantity: request.quantity,
      deviceId: request.deviceId,
    };
    buyFlowStore.beginShoppingCart(beginShoppingCartRequest);
    buyFlowStore.setRecommendSkuFromCurrent(request.sku || DEFAULT_SKU);
  }

  //We shouldn't expect all user who just go to /buy to be brand new users.
  changePageState(request.step ? pageStates[request.step] : pageStates.email);
}

export async function setupCartForUser(request: ISetupCartRequest) {
  const buyFlowStore = useBuyFlowStore();
  const user = useUserStore();
  let beginShoppingCartRequest: IBeginShoppingCartRequest;

  //RatePlanId is the highest value query parameter. If we are passed one it should be used above everything else
  if (request.ratePlanId) {
    const sku = buyFlowStore.getSkuByRatePlan(request.ratePlanId);
    beginShoppingCartRequest = { sku: sku, ratePlanId: request.ratePlanId, quantity: request.quantity };

    if (request.deviceId) {
      beginShoppingCartRequest.deviceId = request.deviceId;
    }

    if (request.licenseKey) {
      beginShoppingCartRequest.licenseKey = request.licenseKey;
    }

    buyFlowStore.beginShoppingCart(beginShoppingCartRequest);

    const ratePlan = buyFlowStore.getRatePlanInfo(request.ratePlanId);
    let step = getBuyFlowStepForRequest(request);
    //Flat rate upgrades should start on the payment step
    if (ratePlan?.isFlatRateUpgrade) {
      step = pageStates.payment;
    }
    changePageState(step);
  } else if (request.deviceId) {
    //Look up the sub based on the device Id
    const subscription = await getSubscriptionFromDeviceId(request.deviceId);

    if (subscription) {
      if (request.sku) {
        beginShoppingCartRequest = {
          deviceId: request.deviceId,
          subscriptionNumber: subscription.subscriptionNumber || request.subscriptionNumber,
          sku: request.sku,
          ratePlanId: buyFlowStore.getRatePlanIdBySku(
            request.sku,
            request.subscriptionMonths || DEFAULT_TERM_LENGTH_MONTHS
          ),
          quantity: request.quantity,
        };
      } else {
        //Use values from given device
        beginShoppingCartRequest = {
          deviceId: request.deviceId,
          subscriptionNumber: subscription.subscriptionNumber || request.subscriptionNumber,
          sku: subscription.sku,
          ratePlanId: subscription.ratePlanId,
          quantity: request.quantity,
        };
      }

      //Get the device name from the computer on the subscription
      buyFlowStore.computerName = subscription.computerName;
      buyFlowStore.setRecommendSkuFromCurrent(subscription.sku);
      buyFlowStore.beginShoppingCart(beginShoppingCartRequest);
      changePageState(getBuyFlowStepForRequest(request));
    } else {
      //If we don't find a subscription for the deviceId,
      //try to setup a new cart with what we have from the query parameters or default items
      setupCartNewUser(request);
    }
  } else if (request.sku) {
    const defaultSkuCatalogItem = buyFlowStore.getCatalogItemBySku(DEFAULT_SKU);
    const catalogItem = buyFlowStore.getCatalogItemBySku(request.sku);

    //Check the upgrade order to set the recommended sku
    if (
      !request.isWebroot &&
      defaultSkuCatalogItem &&
      catalogItem &&
      defaultSkuCatalogItem?.upgradeOrder > catalogItem?.upgradeOrder
    ) {
      buyFlowStore.recommendedPlanSku = DEFAULT_SKU;
    } else {
      buyFlowStore.recommendedPlanSku = request.sku;
    }

    const ratePlanId = buyFlowStore.getRatePlanIdBySku(
      request.sku,
      request.subscriptionMonths || DEFAULT_TERM_LENGTH_MONTHS,
      request.includedUnits
    );

    beginShoppingCartRequest = {
      sku: request.sku,
      ratePlanId: ratePlanId,
      quantity: request.quantity,
      deviceId: request.deviceId,
      subscriptionNumber: request.subscriptionNumber,
      licenseKey: request.licenseKey,
    };
    buyFlowStore.beginShoppingCart(beginShoppingCartRequest);

    changePageState(getBuyFlowStepForRequest(request));
  } else if (request.licenseKey && buyFlowStore.licenseKeyInfo) {
    beginShoppingCartRequest = {
      sku: buyFlowStore.licenseKeyInfo.sku,
      ratePlanId: buyFlowStore.licenseKeyInfo.ratePlanId,
      licenseKey: request.licenseKey,
    };

    buyFlowStore.beginShoppingCart(beginShoppingCartRequest);
    changePageState(getBuyFlowStepForRequest(request));
  } else {
    //Get the default sku and rate plan from the plans.
    //Note:This can be removed when we move a multi cart and allow for an empty cart
    buyFlowStore.recommendedPlanSku = "";
    setDefaultSkuAndTerm(request);
    changePageState(request.step ? pageStates[request.step] : pageStates.email);
  }
  //Once the cart is built add the userGuid
  if (buyFlowStore.cart) {
    buyFlowStore.cart.userGuid = user.currentUser ? user.currentUser.userGuid : request.userGuid || "";
  }
}

export function getBuyFlowStepForRequest(request: ISetupCartRequest) {
  const buyFlowStore = useBuyFlowStore();
  let step = request.step ? pageStates[request.step] : pageStates.email;
  //If there are no candy rack items to show and the user has a safe account move to payment
  if (
    buyFlowStore.licenseKeyInfo?.hasAccount &&
    buyFlowStore.licenseKeyInfo.candyRackItems &&
    buyFlowStore.licenseKeyInfo.candyRackItems.length === 0
  ) {
    step = pageStates.payment;
  }
  return step;
}

export function setDefaultSkuAndTerm(request?: ISetupCartRequest) {
  const buyFlowStore = useBuyFlowStore();
  const defaultProduct = buyFlowStore.getBasePlan();
  let ratePlanId = defaultProduct.ratePlanId;

  if (request && request.subscriptionMonths) {
    ratePlanId = buyFlowStore.getRatePlanIdBySku(defaultProduct.sku, request.subscriptionMonths);
  }

  buyFlowStore.setRecommendSkuFromCurrent();
  const beginShoppingCartRequest = {
    sku: defaultProduct.sku,
    ratePlanId: ratePlanId,
    quantity: request?.quantity || DEFAULT_QUANTITY,
  };
  buyFlowStore.beginShoppingCart(beginShoppingCartRequest);
}

export function getSubscriptionFromDeviceId(deviceId: number): ISubscriptionResult {
  const userStore = useUserStore();
  if (!userStore.currentUser) {
    const unauthenticatedBuyflowStore = useUnauthenticatedBuyFlowStore();
    return {
      computerName: "",
      sku: unauthenticatedBuyflowStore.subscription?.sku || "",
      subscriptionNumber: "",
      ratePlanId: unauthenticatedBuyflowStore.subscription?.productRatePlanId || "",
    } as ISubscriptionResult;
  }

  const subscriptionStore = useSubscriptionsStore();
  const sub = subscriptionStore.subscriptions.find(l => l.computer?.id === deviceId);

  return {
    computerName: sub?.computer?.description || sub?.computer?.name || "",
    sku: sub?.sku || "",
    subscriptionNumber: sub?.zuoraSubscriptionNumber || "",
    ratePlanId: sub?.ratePlanId || "",
  } as ISubscriptionResult;
}

export function getComputerName(computerId): string {
  const buyFlowStore = useBuyFlowStore();
  let name = "";

  const di = getDeviceInfo(parseInt(computerId));
  if (di.device) {
    name = di.device.description || di.device.name;
  } else if (buyFlowStore.getCartItemByComputerId(computerId)) {
    const item = buyFlowStore.getCartItemByComputerId(computerId);
    name = item?.computerDescription || item?.computerName || "";
  }
  return name;
}

//Gets the first item from the cartItems for the expirations date
//Will need to update UX/Code for multi cart items
export function getRenewDate() {
  const buyFlowStore = useBuyFlowStore();
  if (buyFlowStore.cart && buyFlowStore.cart.items.length > 0) {
    // The expirationDate returned by Hoth is the ServiceEndDate which represents the last day of the subscription
    const renewDate = new Date(buyFlowStore.cart?.items[0].expirationDate);
    // So we need to add 1 day to display the proper renew date
    renewDate.setDate(renewDate.getDate() + 1);
    return formatDateString(renewDate.toString() || "");
  }
}

// if we are an annual consumer selecting a monthly plan outside of the conversion period (last 30 days of annual plan or the 30 days after that)
export function isInMonthlyConversionWindow(subscriptionNumber: string) {
  let changeable = true;
  const subscriptionStore = useSubscriptionsStore();
  const subscription = subscriptionStore.subscriptions.find(l => l.zuoraSubscriptionNumber === subscriptionNumber);
  if (subscription) {
    changeable = subscription.remainingDays <= SAFE_RENEWAL_WINDOW_DAYS;
  }
  return changeable;
}

export function changePageState(page: pageStates, clearNotifications = true) {
  const notificationsStore = useNotificationsStore();
  const buyFlowStore = useBuyFlowStore();
  //Always switch page to the purchase layout
  buyFlowStore.layoutState = layoutStates.purchase;
  if (clearNotifications) {
    notificationsStore.clearNotifications();
  }
  buyFlowStore.pageState = page;
  buyFlowStore.triggerStateChange = !buyFlowStore.triggerStateChange;
  const e = document.getElementById("vueApp");
  e?.scrollIntoView();
}

export function buildQueryString(routeQueryParams: LocationQuery, skip: string[] = []) {
  let queryParams = "";
  for (const key in routeQueryParams) {
    //skip keys found in the skip array
    if (skip.find(l => l === key)) {
      continue;
    }

    if (!queryParams) {
      queryParams = `${key}=${routeQueryParams[key]}`;
    } else {
      queryParams = `${queryParams}&${key}=${routeQueryParams[key]}`;
    }
  }

  return queryParams;
}

export async function loadPaymentMethods(forcePaymentsCall = false, bubbleUpError = false) {
  try {
    const buyFlowStore = useBuyFlowStore();
    const userStore = useUserStore();
    let paymentMethods: IPaymentMethod[] = [];

    if (userStore.isEstablishedAccount || forcePaymentsCall) {
      const response = (await unifiedApi.getUserPaymentMethods()).data;
      if (response["paymentMethods"]) {
        paymentMethods = response["paymentMethods"];
      }
    } else {
      //If there is no payment method id return empty array to avoid error page
      if (!buyFlowStore.cart?.paymentMethodId) {
        return [];
      }
      //All credit cards added during an unauthenticated or preliminary flow are orphaned by default. We need to pass an empty GUID to get those cards back
      const response = (
        await unifiedApi.getUserPaymentMethodGuest(EMPTY_GUID, buyFlowStore.cart?.paymentMethodId ?? "")
      ).data;

      if (!response.id) {
        return paymentMethods;
      }

      const paymentMethod = {
        address: response.address,
        email: response.email,
        expirationDate: response.expirationDate, //Create a date string for the first of the given month
        lastDigits: response.lastDigits,
        cardType: response.cardType,
        id: response.id,
        accountHolder: response.accountHolder,
        defaultPaymentMethod: response.defaultPaymentMethod,
      } as IPaymentMethod;

      paymentMethods[0] = paymentMethod;
    }

    return paymentMethods;
  } catch (err) {
    if (bubbleUpError) {
      throw err;
    } else {
      handleApiError(err as AxiosError);
    }
  }
}

export function handlePurchaseError(
  error: string,
  statusCode: number | undefined = undefined,
  isFromPayPalReturn = false,
  payPalReturnRouteName = buyFlowRouteName
) {
  const notificationsStore = useNotificationsStore();
  const paymentPageStore = usePaymentPageStore();
  let notificationError = error;

  const errorObject = NotificationLookup[error];

  //If there is no match to the error check if it matches the purchase errors
  if (!errorObject) {
    const notification =
      error === purchaseErrors.CCAddress
        ? purchaseErrorsNotifications.CCAddress
        : error === purchaseErrors.CCTransaction
          ? purchaseErrorsNotifications.CCTransaction
          : purchaseErrors.PurchaseFailureError; // This error will only show up if statusCode is not 400

    if (statusCode === 400 && notification === purchaseErrors.PurchaseFailureError) {
      notificationsStore.addNotification({
        type: purchaseErrorsNotifications.PurchaseFailureError,
        dismissible: false,
      });
      return;
    }

    notificationError = notification;
  }

  //Go back to payment step and display CC form
  paymentPageStore.pageState = paymentPageStates.forceAddCard;

  if (isFromPayPalReturn) {
    //Remove the item that triggers the auto renew success banner
    sessionStorage.removeItem("product_name_for_auto_renew");
    const payPalStore = usePayPalStore();
    payPalStore.purchaseError = notificationError as purchaseErrorsNotifications;
    const params = { from: "Paypal" };

    if (payPalReturnRouteName === buyFlowRouteName) {
      params["step"] = pageStates.payment;
    }

    router.push({
      name: payPalReturnRouteName,
      params: params,
    });
    return;
  }

  //use passed in error
  notificationsStore.addNotification({ type: notificationError as NotificationType, dismissible: false });

  changePageState(pageStates.payment, false);
}

// Sends an empty cart to backend to clear the cart in DB
// Should not clear the store
export async function clearShoppingCartFromDb() {
  const buyFlowStore = useBuyFlowStore();
  const emptyCart: IShoppingCart = {
    items: [],
    userGuid: "",
    promotionCode: "",
    promoDetail: [],
    campaignId: 0,
    prospectId: 0,
    paymentMethodId: "",
    adjustments: [],
    total: 0,
    zuoraAccountId: "",
    brand: buyFlowStore.openTextBrand,
  };
  await unifiedApi.saveShoppingCart(emptyCart);
  cartUpdate(emptyCart, googleTagCartEvents.remove);
}

// Check if user is authenticated and reload user store if empty
// This will prevent a authenticated user get on a unauthenticated state if page refreshed
export async function checkLoggedStatus() {
  const userStore = useUserStore();
  try {
    const currentUser = (await unifiedApi.getUser(true))?.data;
    if (currentUser && !userStore.currentUser) {
      await reloadSubscriberInfo();
    }
  } catch {
    logEvent("User not logged in", "buyHelper");
  }
}

export async function getProspectId(prospectGuid: string): Promise<number | undefined> {
  try {
    if (prospectGuid && prospectGuid != "") {
      return (await unifiedApi.getProspectId(prospectGuid)).data;
    }
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

export function redirectToUnifiedPortalSmbBuyflow() {
  window.location.href = `${URLS.CARBONITE_BUY_URL}/selectplan`;
}

export function redirectToOutstandingInvoice(redirectToLogIn = false) {
  if (redirectToLogIn) {
    window.location.href = `${URLS.VITE_APP_URL}subscriptions`;
  } else {
    router.push({
      name: "subscription_information",
    });
  }
}

export function checkShippingFormForErrors() {
  const buyFlowStore = useBuyFlowStore();
  const skipValidation = ["address2"];

  for (const key in buyFlowStore.shippingAddress) {
    //skip form items that are not required
    if (skipValidation.indexOf(key) >= 0) {
      continue;
    }

    //check for valid format
    validateShippingFormFields(key);
  }

  return Object.values(buyFlowStore.showShippingFormErrors).some(l => l.length > 0);
}

export function getBrandFromCart() {
  const buyFlowStore = useBuyFlowStore();
  return buyFlowStore.isWebrootSku(buyFlowStore?.cart?.items[0].sku || "")
    ? openTextBrands.Webroot
    : openTextBrands.Carbonite;
}

export function validateShippingFormFields(key) {
  const buyFlowStore = useBuyFlowStore();

  switch (key) {
    case "firstName":
      buyFlowStore.showShippingFormErrors[key] = isFirstNameValid(buyFlowStore.shippingAddress[key]);
      break;
    case "lastName":
      buyFlowStore.showShippingFormErrors[key] = isLastNameValid(buyFlowStore.shippingAddress[key]);
      break;
    case "state":
      buyFlowStore.showShippingFormErrors[key] = isStateValid(buyFlowStore.shippingAddress[key]);
      break;
    case "address1":
      buyFlowStore.showShippingFormErrors[key] = isAddress1Valid(buyFlowStore.shippingAddress[key]);
      break;
    case "city":
      buyFlowStore.showShippingFormErrors[key] = isCityValid(buyFlowStore.shippingAddress[key]);
      break;
    case "zipCode":
      buyFlowStore.showShippingFormErrors[key] = isZipValid(buyFlowStore.shippingAddress[key]);
      break;
  }
}

export function extractFirstLastFromFullName(fullName: string): string[] {
  const nameElements = fullName.split(" ");
  let firstName = "";
  let lastName = "";
  if (nameElements && nameElements?.length > 0) {
    firstName = nameElements[0];
    if (nameElements.length > 1) {
      lastName = nameElements.reduce((p, c, i) => {
        if (i == 1) return c;
        return `${p} ${c}`;
      });
    }
  }
  return [firstName, lastName];
}

export function setShippingAddressFromCC(paymentMethod: IPaymentMethod) {
  const buyFlowStore = useBuyFlowStore();
  const paymentMethodAddress = paymentMethod?.address;
  if (buyFlowStore.cart) {
    //this logic gets us a preliminary crack at first/last name
    //because we're putting it into the shipping address, it is possible for the user to edit this
    //we may want to save this adjusted first/last name to pass to Allstate
    //also, PayPal does give us a separated first/last name, so the payment method may include
    //already separated names that we can use directly in the future
    let name = ["", ""];
    if (paymentMethod?.accountHolder) {
      name = extractFirstLastFromFullName(paymentMethod.accountHolder);
    }
    buyFlowStore.cart.shippingAddress = {
      firstName: name[0],
      lastName: name[1],
      address1: paymentMethodAddress.streetAddress1 || "",
      address2: paymentMethodAddress.streetAddress2 || "",
      city: paymentMethodAddress.cityName || "",
      state: paymentMethodAddress.stateOrProvince || "",
      zipCode: paymentMethodAddress.postalCode || "",
      country: paymentMethodAddress.country || "",
    };
  }
}

export function setPaymentMethodById(paymentMethodId: string) {
  const paypalStore = usePayPalStore();
  const buyFlowStore = useBuyFlowStore();

  const paymentMethod = buyFlowStore.creditCards.find(l => l.id === paymentMethodId);
  if (paymentMethod) {
    buyFlowStore.paymentType =
      paymentMethod.cardType === paymentTypes.PayPal ? paymentTypes.PayPal : paymentTypes.CreditCard;

    if (paymentMethod.cardType === paymentTypes.PayPal) {
      const payPalResponse = {
        email: paymentMethod.email ?? "",
        paymentMethodId: paymentMethodId,
        shippingAddress: {
          streetAddress1: paymentMethod.address.streetAddress1,
          streetAddress2: paymentMethod.address.streetAddress2,
          cityName: paymentMethod.address.cityName,
          stateOrProvince: paymentMethod.address.stateOrProvince,
          postalCode: paymentMethod.address.postalCode,
          country: paymentMethod.address.country,
        } as IPayPalShippingAddressInfo,
      } as IPayPalApprovalResponse;

      paypalStore.setPayPalBillingAgreementResponseManually(payPalResponse);
    }

    //Set the shipping info if the user has not input a different one
    if (buyFlowStore.shippingMatchesBilling) {
      setShippingAddressFromCC(paymentMethod);
      if (buyFlowStore.cart?.shippingAddress) {
        buyFlowStore.shippingAddress = buyFlowStore.cart.shippingAddress;
      }
    }
  }

  buyFlowStore.updateCreditCard(paymentMethodId);
}

export async function continueToMyAccount() {
  const buyFlowStore = useBuyFlowStore();
  const userStore = useUserStore();
  const siteSettingsStore = useSiteSettingsStore();

  let newRoute = buyFlowStore.cart?.brand === openTextBrands.Webroot ? "downloads" : "backup";
  if (buyFlowStore.isAllStateOrVpn()) {
    newRoute = "home";
  }

  if (userStore.currentUser) {
    buyFlowStore.showProcessingOrderMessage = true;
    //Skip the security questions for new accounts and logged in users.
    siteSettingsStore.setSkipAccountSetup(true);

    // Update stores before getting back to navigation -- Wait 5 secs for provision to complete
    await new Promise(resolve => setTimeout(resolve, 5000));

    await Promise.all([
      useSubscriptionsStore().forceRefreshAndPopulateSubscriptions(),
      useSecurityStore().forceRefresh(),
      getUserDetailsFromStore(true),
      reloadSubscriberInfo(),
    ]);
    buyFlowStore.showProcessingOrderMessage = false;

    if (buyFlowStore.isNewUser) {
      siteSettingsStore.redirectPreliminaryUserToBackup = true;
      //If a new user (preliminary account), VD shall send the customer to the password set up page
      router.push({ name: "setNewPassword" });
    } else {
      //If an existing user (established account) logged in, VD shall send the customer to the backup tab
      router.push(newRoute);
    }
  } else {
    //If an existing user (established account) did not log in, VD shall send the customer to the log in page and then the backup tab
    // this gets the current domain and maintains it in the returnUrl sent to Login
    const currentDomain = new URL(window.location.href);
    const redirectDomain = currentDomain.origin + "/";
    if (userStore.email) {
      window.location.href = getRedirectToLoginURLWithEmail(`${redirectDomain}${newRoute}`, userStore.email);
    } else {
      window.location.href = getRedirectToLoginURL(`${redirectDomain}${newRoute}`);
    }
  }
}

export function getLicenseKeyFromCartItems(cartItems: ICartItem[]) {
  return getCartItemWithLicenseKey(cartItems)?.licenseKey ?? "";
}

export function getCartItemWithLicenseKey(cartItems: ICartItem[]) {
  return cartItems.find(l => l.licenseKey);
}

//This should only be used if the user is not logged in.
export function getWebrootInterstitialLinkUnauthenticated() {
  const buyFlowStore = useBuyFlowStore();
  const portalDestParam = generateInterstitialReturnPortalDestination("&");
  return `${getUrl("WEBROOT_INTERSTITIAL")}${buyFlowStore.licenseKey}${portalDestParam}`;
}

export function getSpecialOffersToDisplay(mainItem: ICartItem | undefined) {
  const buyFlowStore = useBuyFlowStore();
  const userStore = useUserStore();
  const subscriptionsStore = useSubscriptionsStore();
  let products: IProductCatalog[] = [];

  if (mainItem) {
    //Find all the candy rack items that are not in the cart already
    const candyRackItems = buyFlowStore.plans
      .find(l => l.sku === mainItem?.sku)
      ?.candyRackItems?.filter(l => !buyFlowStore.cart?.items.some(k => k.sku === l));
    if (candyRackItems) {
      for (const item of candyRackItems) {
        const product = buyFlowStore.plans.find(
          l => l.sku === item && !l.candyRackSettings?.bundleWithCart && l.ratePlans.some(k => k.canPurchaseOnline)
        );
        if (product) {
          products.push(product);
        }
      }
    }
    //if we are logged in, then check to see if the user already has any of these items
    //and remove if the product catalog says to
    if (userStore.isEstablishedAccount) {
      const productsToRemove: IProductCatalog[] = [];
      const subscriptions = processSubscriptionDataForDisplay(subscriptionsStore.subscriptions);
      if (subscriptions && subscriptions.length > 0) {
        for (const product of products) {
          if (product.candyRackSettings?.hideIfOwned) {
            //the user could have more than one matching subscription, check them all
            const matches = subscriptions.filter(
              s => convertWebrootSku(s.sku ?? "", s.licenseCategoryName ?? "") === product.sku
            );
            for (const match of matches) {
              if (match.expiration !== ExpirationState.Purged) {
                productsToRemove.push(product);
                break;
              }
            }
          }
        }
      }

      if (productsToRemove.length > 0) {
        products = products.filter(p => !productsToRemove.some(pr => pr.sku === p.sku));
      }

      if (
        subscriptions &&
        subscriptions.some(
          l => allstateLCNs.includes(l.licenseCategoryName ?? "") && l.remainingDays > SUBSCRIPTION_LAST_ALLSTATE_DAY
        )
      ) {
        products = products.filter(p => !webrootOnlyAllstateSkus.some(pr => pr === p.sku));
      }
    } else if (buyFlowStore.licenseKeyInfo?.candyRackItems) {
      const skuToHide = [webrootCDSku];
      const productToShow: IProductCatalog[] = [];
      buyFlowStore.licenseKeyInfo?.candyRackItems.forEach(sku => {
        if (skuToHide.some(l => l === sku)) {
          return;
        }
        const product = buyFlowStore.plans.find(l => l.sku === sku && l.ratePlans.some(k => k.canPurchaseOnline));
        const isInCart = buyFlowStore.cart?.items.some(i => i.sku === sku);
        if (product && !isInCart) {
          productToShow.push(product);
        }
      });

      //OverWrite the candy rack items with the products from the API call
      products = productToShow;
    }
  }

  //Remove Allstate products if selected language is not English
  const selectedLanguage = userStore.selectedLanguage;
  if (selectedLanguage !== "en") {
    products = products.filter(p => !webrootOnlyAllstateSkus.some(pr => pr === p.sku));
  }

  return products;
}

//Does a case insensitive look up for the given item's value from the query params
export function getQueryParam(params: LocationQuery, item: string) {
  let paramValue = "";
  if (params !== null) {
    for (const [key, value] of Object.entries(params)) {
      if (key.toLowerCase() === item.toLowerCase()) {
        paramValue = value ? value.toString() : "";
        break;
      }
    }
  }

  return paramValue;
}
