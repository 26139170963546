<template>
  <ModalWrapperComponent
    dialog-name="DownloadProductComponent"
    :show-dialog="showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      <span v-if="showReinstallText">{{ t("DownloadProductComponent.ReinstallSafe") }}</span>
      <span v-else>{{ t(product?.localizedName || "") }}</span>
    </template>

    <template #content>
      <SafeProductComponent
        v-if="props.productName === 'SAFE'"
        :activation-code="activationCode"
        :device-id="deviceId"
        :install-type="installType"
        :product-name="props.productName"
        :unsupported-o-s="isUnsupportedOs"
      />
      <WSAPProductComponent
        v-if="props.productName === 'WSAP'"
        :keycodes="keycodes"
        :is-unsupported-os="isUnsupportedOs"
      />
      <WWTSProductComponent
        v-if="props.productName === 'WWTS'"
        :keycodes="keycodes"
        :is-unsupported-os="isUnsupportedOs"
      />
      <WRMSProductComponent
        v-if="props.productName === 'WRMS'"
        :keycodes="keycodes"
        :is-unsupported-os="isUnsupportedOs"
      />
      <WSCBProductComponent
        v-if="props.productName === 'WSCB'"
        :keycodes="keycodes"
        :is-unsupported-os="isUnsupportedOs"
      />
      <WIFIProductComponent v-if="props.productName === 'WIFI'" :is-unsupported-os="isUnsupportedOs" />
      <LPPMProductComponent v-if="props.productName === 'LPPM'" :is-unsupported-os="isUnsupportedOs" />
      <SAFEMOBILEProductComponent v-if="props.productName === 'SAFEMOBILE'" :is-unsupported-os="isUnsupportedOs" />
      <WSVPNProductComponent v-if="props.productName === 'WSVPN'" :is-unsupported-os="isUnsupportedOs" />
      <WPCOProductComponent
        v-if="props.productName === 'WPCO'"
        :keycodes="keycodes"
        :is-unsupported-os="isUnsupportedOs"
      />
      <WTP v-if="props.productName === 'WTP'" :keycodes="keycodes" :is-unsupported-os="isUnsupportedOs" />
    </template>
    <template #footer>
      <div class="opposite-ends-desktop">
        <div id="linksModalFooter">
          <a v-if="helpLink" id="linkNeedHelp" class="mb-half" :href="helpLink" target="_blank">
            {{ helpText }}
            <svg-icon-component icon="external-link" class="pl-quarter" />
          </a>
        </div>
        <div id="buttonsModalFooter">
          <button id="btnCloseModal" type="button" class="btn-outline-primary" @click="close()">
            {{ tn("btnClose") }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref, computed, onUnmounted } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscriberDownloadsInfo } from "@/common/api/unifiedPortal/interfaces";
import { IProduct, productList } from "@/common/productList";
import { getOsFamily } from "@/common/browserDetect";
import SafeProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/Safe.vue";
import WSAPProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WSAP.vue";
import WWTSProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WWTS.vue";
import WRMSProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WRMS.vue";
import WSCBProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WSCB.vue";
import WIFIProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WIFI.vue";
import LPPMProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/LPPM.vue";
import WSVPNProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WSVPN.vue";
import WPCOProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WPCO.vue";
import WTP from "@/components/shared/dialogs/DownloadProduct/Products/WTP.vue";
import SAFEMOBILEProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/SAFEMOBILE.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getUrl } from "@/common/getUrl";
import { registerRefreshFunction, clearRefreshFunctions } from "@/components/shared/LanguageSelector/LanguageSelector";

const componentName = "DownloadProductComponent";

const props = defineProps({
  productName: {
    type: String,
    default: "SAFE",
  },
  keycodes: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
  activationCode: {
    type: String,
    default: null,
  },
  deviceId: {
    type: Number,
    default: null,
  },
  installType: {
    type: String,
    default: null,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["close-modal"]);

logEvent("created", componentName, props);
const product = ref<IProduct>();
let safeReinstallSupportUrl = getUrl("VITE_APP_SAFE_REINSTALL_SUPPORT");
let safeRestoreAllFilesSupportUrl = getUrl("VITE_APP_SAFE_RESTORE_ALL_FILES_SUPPORT");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const helpLink = computed(() => {
  return props.productName !== "SAFE"
    ? productList().find(c => c.code === props.productName)?.supportURL
    : props.installType === "Reinstall"
      ? safeReinstallSupportUrl
      : safeRestoreAllFilesSupportUrl;
});

function refreshDownloadProductUrls() {
  safeReinstallSupportUrl = getUrl("VITE_APP_SAFE_REINSTALL_SUPPORT");
  safeRestoreAllFilesSupportUrl = getUrl("VITE_APP_SAFE_RESTORE_ALL_FILES_SUPPORT");
}

registerRefreshFunction(refreshDownloadProductUrls);

const helpText = computed(() => productList().find(c => c.code === props.productName)?.supportURLText);

function close() {
  emits("close-modal");
}

function beforeOpen() {
  product.value = productList().find(c => c.code === props.productName);
  if (!product.value) {
    logEvent("Product not found", componentName, props.productName);
    return;
  }
}

const showReinstallText = computed(() => {
  return props.productName === "SAFE" && props.installType === "Reinstall";
});

// checks if OS is supported to display the modal for the product
const isUnsupportedOs = computed(() => {
  if (props.productName === "SAFE") {
    return false;
  }
  return !product.value?.supportedOS.find(c => c.toLowerCase() === getOsFamily().toLowerCase());
});

onUnmounted(() => {
  clearRefreshFunctions();
});
</script>
