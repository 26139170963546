import { adjustments } from "@/components/Buy/BuyEnums";
import { IShoppingCart } from "./api/unifiedPortal/interfaces";
import { logEvent, logException } from "./logger";
import { bazaarVoiceWebrootProductIds, webrootProducts } from "./webrootProductName";

export function bazaarVoiceWebrootInitialize(scriptUrl: string) {
  // Create and configure the script element
  const script = document.createElement("script");
  script.async = true;
  script.src = scriptUrl;

  // Set up event handlers for script loading
  script.onload = () => {
    logEvent("webroot bazaarVoice script loaded");
  };

  script.onerror = () => {
    logEvent("Failed to load the webroot bazaarVoice script");
  };

  // Append the script element to the document head
  const firstScript = document.getElementsByTagName("script")[0];
  if (firstScript.parentNode != null) firstScript.parentNode.insertBefore(script, firstScript);
}

export function bvWebrootEventHandler(userShoppingCart: IShoppingCart) {
  // we have to setup different BV if we load carbonite bazaar voice scripts
  const BV = window.BV as IBV | undefined;
  if (BV && BV.pixel && typeof BV.pixel.trackTransaction === "function") {
    const bvItems: IBVItem[] = [];

    userShoppingCart.items.forEach(item => {
      if (item.bundledItems && item.bundledItems.length > 0) {
        item.bundledItems.forEach(bundledItem => {
          const bundleProductId =
            bazaarVoiceWebrootProductIds[webrootProducts.find(x => x.sku === bundledItem.sku)?.licenseCategory ?? ""];
          if (bundleProductId) {
            bvItems.push({
              price: bundledItem.total.toFixed(2),
              quantity: bundledItem.quantity,
              productId: bundleProductId,
              sku: bundleProductId,
              name: bundledItem.sku,
            });
          }
        });
      }

      const productId =
        bazaarVoiceWebrootProductIds[webrootProducts.find(x => x.sku === item.sku)?.licenseCategory ?? ""];

      if (productId) {
        bvItems.push({
          price: item.total.toFixed(2),
          quantity: item.quantity,
          productId: productId,
          sku: productId,
          name: item.sku,
        });
      }
    });

    const transactionData: IBVTransactionData = {
      currency: "USD",
      orderId: userShoppingCart.orderCode ?? "",
      total: userShoppingCart.total.toFixed(2),
      items: bvItems,
      encryptedEmail: userShoppingCart.encryptedEmail ?? "",
      encryptionKeyId: userShoppingCart.encryptionKeyId ?? "",
      tax: userShoppingCart?.adjustments.find(a => a.type == adjustments.Tax)?.amount.toString() ?? "0",
    };

    try {
      if (transactionData.items.length > 0) {
        // sends a purchase event to bazaar voice
        BV.pixel.trackTransaction(transactionData);
      }
    } catch (error) {
      logException(error as Error, `bazaarVoiceEvents`);
    }
  }
}

interface IBVItem {
  price: string;
  quantity: number;
  productId: string; // productId and sku both are same
  sku: string;
  name: string;
  optional_item_parameter?: string; // Optional parameters can be included
}

interface IBVTransactionData {
  currency: string;
  orderId: string;
  total: string;
  items: IBVItem[];
  tax: string;
  optional_order_parameter?: string; // Optional parameters
  optional_PII_parameter?: string; // Optional parameters
  encryptedEmail: string;
  encryptionKeyId: string;
}

interface IBVPixel {
  trackTransaction(data: IBVTransactionData): void;
}

interface IBV {
  pixel: IBVPixel;
}

declare global {
  interface Window {
    BV?: IBV;
  }
}
