<template>
  <ul v-if="props.isUnsupportedOs" class="form">
    <li class="danger-text">
      {{ t(`${parentComponentName}.${productCode}Unsupported`) }}
    </li>
    <li>
      {{ t(`${parentComponentName}.${productCode}UnsupportedReference`) }}
      <Keycodes :keycodes="keycodes" />
    </li>
  </ul>

  <div v-else class="form">
    <ol>
      <li>
        <p>{{ t(`${parentComponentName}.${productCode}StepOne`) }}</p>
        <SelectorComponent :product-code="productCode" />
      </li>
      <li>
        <p>{{ t(`${parentComponentName}.${productCode}StepTwo`) }}</p>
        <Keycodes :keycodes="keycodes" />
      </li>
    </ol>

    <p>{{ t(`${parentComponentName}.${productCode}Note`) }}</p>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";
import Keycodes from "@/components/Downloads/Keycodes.vue";
import { ISubscriberDownloadsInfo } from "@/common/api/unifiedPortal/interfaces";
import SelectorComponent from "@/components/shared/dialogs/DownloadProduct/Selector.vue";

const productCode = "WTP";
const componentName = `${productCode}ProductComponent`;
const parentComponentName = "DownloadProductComponent";

const props = defineProps({
  keycodes: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
  isUnsupportedOs: {
    type: Boolean,
    default: null,
  },
});

logEvent("created", componentName, props);
</script>
