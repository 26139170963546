<template>
  <div v-if="loading">
    <LoadingSpinner />
  </div>
  <div v-else class="content">
    <PrimaryEmailVerificationWrapperComponent notification-context="AllstateSignUpComponent" :email="email" />
    <div v-if="pageState === pageStates.alreadySignedUp">
      <AllstateAlreadySignedUpComponent
        :subscriptions="allstateSubscriptions"
        :webroot-subscription-name="subscriptionName"
        :webroot-subscription-sku="subscriptionSku"
        :is-expired="isExpired"
        :keycode="keycode"
        :just-set="justSet"
        :force-show-manage-btn="activeSubscription?.hasOutstandingInvoices"
      />
    </div>
    <div v-else-if="pageState === pageStates.canSignUp">
      <AllstateSignUpFormComponent :keycode="keycode" :is-inactive="isInactive" @completed="completeSignUp" />
    </div>
    <div v-else-if="pageState === pageStates.upgrade">
      <AllstateUpgradeComponent :subscriptions="subscriptions" />
    </div>
    <div v-else>
      <AllstateNotPurchasedComponent />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onBeforeMount, watch } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { useNotificationsStore } from "@/stores/notifications";
import { IAllstateResponse, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import AllstateSignUpFormComponent from "./AllstateSignUpForm.vue";
import AllstateAlreadySignedUpComponent from "./AllstateAlreadySignedUp.vue";
import AllstateUpgradeComponent from "./AllstateUpgrade.vue";
import AllstateNotPurchasedComponent from "./AllstateNotPurchased.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { handleApiError } from "@/common/handleApiError";
import PrimaryEmailVerificationWrapperComponent from "@/components/shared/PrimaryEmailVerificationWrapper.vue";
import { AxiosError } from "axios";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { pageStates } from "./AllstateEnums";
import { useUserStore } from "@/stores/user";
import { KeyCodeSeller } from "@/globalEnums";
import { useRouter } from "vue-router";
import { SUBSCRIPTION_MIN_EXPIRED_DAY, SUBSCRIPTION_MIN_ACTIVE_DAY, SUBSCRIPTION_LAST_ALLSTATE_DAY } from "@/define";
import { useAllstateSubscriptionsStore } from "@/stores/allstateSubscriptions";
import { allstateLCNs, bestBuyAllStateLCNs } from "@/common/webrootProductName";
import { unifiedApi } from "@/common";
import { hasExpiredBestBuyAllState } from "./AllstateHelper";

const componentName = "AllstateSignUpComponent";

logEvent("created", componentName);

const notificationsStore = useNotificationsStore();
const userStore = useUserStore();
const loading = ref<boolean>(true);
const allstateSubscriptions = ref<IAllstateResponse[]>([]);
const subscriptions = ref<ISubscription[]>([]);
const subscriptionsStore = useSubscriptionsStore();
const allstateSubscriptionsStore = useAllstateSubscriptionsStore();
const email = ref<string>("");
const user = computed(() => userStore.currentUser);
const router = useRouter();
const pageState = ref<pageStates>(pageStates.notPurchased);
const subscriptionName = ref<string>("");
const subscriptionSku = ref<string>("");
const keycode = ref<string>("");
const isExpired = ref<boolean>(false);
const isExpiring = ref<boolean>(false);
const isInactive = ref<boolean>(false);
const justSet = ref<boolean>(false);
const activeSubscription = ref<ISubscription>();

const isExpiredBestBuyAllState = ref<boolean>(false);

// Possible pageStates:
// alreadySignedUp - User has a Allstate subscription already
// canSignUp - User has a Webroot Premium (WSAP), or a add-on (WSID) Webroot License
// upgrade - User has a Webroot license bought from ECom (keyCodeSellerType === Webroot), but not one above
// notPurchased - User has other subscriptions such as Safe or even Webroot from Carbonite. It need to buy a new subscription from ECom.
async function loadPageState() {
  pageState.value =
    allstateSubscriptions.value.length > 0 && allstateSubscriptions.value.some(a => !a.canceledOnDate)
      ? pageStates.alreadySignedUp
      : subscriptions.value &&
          subscriptions.value.some(
            l =>
              allstateLCNs.includes(l.licenseCategoryName ?? "") &&
              (l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY || l.webrootStatusDescription === "Inactive")
          )
        ? pageStates.canSignUp
        : subscriptions.value.some(
              l =>
                (l.keyCodeSellerType === KeyCodeSeller.Webroot || l.keyCodeSellerType === KeyCodeSeller.BestBuy) &&
                l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY
            )
          ? pageStates.upgrade
          : pageStates.notPurchased;

  // get activeSubscription and calculate the subscriptionName, keycode and expiration state
  activeSubscription.value =
    allstateSubscriptions.value.length > 0 &&
    subscriptions.value.some(
      l =>
        l.keyCode === allstateSubscriptions.value[0].keyCode &&
        l.keyCodeSellerType === KeyCodeSeller.Webroot &&
        !allstateSubscriptions.value[0].canceledOnDate
    )
      ? subscriptions.value.find(
          l =>
            l.keyCode === allstateSubscriptions.value[0].keyCode &&
            l.keyCodeSellerType === KeyCodeSeller.Webroot &&
            !allstateSubscriptions.value[0].canceledOnDate
        )
      : subscriptions.value.some(
            l => allstateLCNs.includes(l.licenseCategoryName ?? "") && l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY
          )
        ? subscriptions.value.find(
            l => allstateLCNs.includes(l.licenseCategoryName ?? "") && l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY
          )
        : subscriptions.value.find(
            l => allstateLCNs.includes(l.licenseCategoryName ?? "") && l.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY
          );

  if (activeSubscription.value) {
    subscriptionName.value = t(`Brand.${activeSubscription.value.licenseCategoryName}`);
    subscriptionSku.value = activeSubscription.value.licenseCategoryName ?? "";
    keycode.value = activeSubscription.value.keyCode ?? "";

    if (activeSubscription.value.webrootStatusDescription === "Inactive") {
      notificationsStore.addNotification({ type: "InactiveKeycodeAllstateError" });
      isInactive.value = true;
      return;
    }
    const webrootUserInfo = (await unifiedApi.getWebrootPIIInformation(activeSubscription.value.keyCode ?? ""))?.data;
    const canAutoEnroll = autoEnrollEnabled(webrootUserInfo);

    isExpired.value =
      activeSubscription.value.hasOutstandingInvoices ||
      (activeSubscription.value.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY &&
        activeSubscription.value.remainingDays >= SUBSCRIPTION_LAST_ALLSTATE_DAY);

    if (isExpiredBestBuyAllState.value) {
      pageState.value = pageStates.alreadySignedUp;
    } else if (activeSubscription.value.hasOutstandingInvoices || isExpired.value) {
      pageState.value = pageStates.alreadySignedUp;

      notificationsStore.addNotification({
        type: canAutoEnroll ? "AllstateExpiredError" : "AllstateExpiredErrorReEnroll",
        params: { subscription: subscriptionName.value, keycode: keycode.value },
      });

      return;
    }
    isExpiring.value =
      activeSubscription.value.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY &&
      activeSubscription.value.remainingDays <= SUBSCRIPTION_MIN_ACTIVE_DAY &&
      !activeSubscription.value.isAutoRenewEnabled;

    //Get all the non BestBuy AllState subs
    const allStateSubsNonBestBuy = allstateSubscriptions.value.filter(l => {
      const sub = subscriptions.value.find(n => n.keyCode === l.keyCode);
      if (!sub) {
        return false;
      }

      return !bestBuyAllStateLCNs.some(k => k === sub.licenseCategoryName);
    });

    if (isExpiring.value && allStateSubsNonBestBuy.length > 0) {
      notificationsStore.addNotification({
        type: canAutoEnroll ? "AllstateExpiringWarning" : "AllstateExpiringWarningReEnroll",
        params: { subscription: subscriptionName.value, keycode: keycode.value },
      });
      return;
    }
  }
}

email.value = user.value?.email ?? "";

if (user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail) {
  notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
}

async function completeSignUp(response: IAllstateResponse) {
  notificationsStore.clearNotifications();
  allstateSubscriptions.value = [];
  allstateSubscriptions.value.push(response);
  notificationsStore.addNotification({ type: "AllstateSignUpComplete" });
  justSet.value = true;
  await loadPageState();
}

function autoEnrollEnabled(webrootUserInfo) {
  if (!webrootUserInfo) {
    return false;
  }

  return (
    webrootUserInfo.country === "US" &&
    webrootUserInfo.firstName?.length > 0 &&
    webrootUserInfo.lastName?.length > 0 &&
    webrootUserInfo.address1?.length > 0 &&
    webrootUserInfo.state?.length > 0 &&
    webrootUserInfo.city?.length > 0 &&
    webrootUserInfo.postalCode?.length > 0
  );
}

onBeforeMount(async () => {
  logEvent("mounting", componentName);
  redirectIfNotEnglish();
  notificationsStore.clearNotifications();
  loading.value = true;
  try {
    allstateSubscriptions.value = (await allstateSubscriptionsStore.getAllstateSubscriptions()).allstateSubscriptions;
    await subscriptionsStore.populateSubscriptions();
    isExpiredBestBuyAllState.value = await hasExpiredBestBuyAllState();
    subscriptions.value = subscriptionsStore.subscriptions?.sort((a, b) => {
      const statusA = a.webrootStatusDescription?.toUpperCase();
      const statusB = b.webrootStatusDescription?.toUpperCase();
      return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
    });
  } catch (error) {
    handleApiError(error as AxiosError);
  }
  await loadPageState();
  loading.value = false;
});

const language = computed(() => userStore.selectedLanguage);
watch(language, () => redirectIfNotEnglish());

function redirectIfNotEnglish() {
  // Do not display if japanese language is set
  if (language.value !== "en") {
    router.push("/home");
  }
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
