<template>
  <div>
    <NotificationsComponent context="BuyflowComponent" />
    <article class="main m-auto constrain-90 pa-desktop">
      <BuyProgressbar />

      <!-- if Carbonite sku -->
      <div v-if="buyFlowStore.cart?.brand === openTextBrands.Carbonite">
        <!-- if full Carbonite account renewed or upgraded -->
        <div v-if="buyFlowStore.isRenewOrUpgrade" class="mb-double">
          <CarboniteRenewUpgrade />
        </div>
        <!-- if is New User -->
        <!-- if provisional Carbonite (preliminary) account on windows or mac -->
        <div v-else-if="buyFlowStore.isNewUser" class="mb-double pb">
          <CarboniteProvisional />
        </div>
        <!-- is established Carbonite account and is not a renew or upgrade -->
        <div v-else class="mb-double">
          <CarboniteEstablishedAccount />
        </div>
      </div>
      <div v-else-if="buyFlowStore.cart?.brand === openTextBrands.Webroot && FLAGS.ENABLE_BUYFLOW_WEBROOT">
        <WebrootThanks />
        <div v-if="buyFlowStore.isRenewOrUpgrade" class="mb-double">
          <WebrootNextStepsRenewUpgrade />
        </div>
        <div v-else-if="buyFlowStore.isNewUser" class="mb-double">
          <WebrootNextStepsProvisional />
        </div>
        <div v-else class="mb-double">
          <WebrootNextStepsEstablished />
        </div>
        <p v-if="!buyFlowStore.hasOnlyAllStateInCart && !buyFlowStore.hasOnlyCDLPinCart" class="mb">
          {{ t("NextSteps.downloadInstructionsBelow") }}
        </p>
        <div v-if="buyFlowStore.hasAllStateInCart" class="mb-double">
          <WebrootNextStepsAllstate />
        </div>
        <h1 v-if="!buyFlowStore.hasOnlyAllStateInCart && !buyFlowStore.hasOnlyCDLPinCart">
          {{ t("WebrootDownloadInstructions.downloadInstructions") }}
        </h1>
        <div v-if="hasStandardWebroot">
          <WebrootAllOthers />
        </div>
        <div v-if="hasChromeBook" class="mb-double">
          <WebrootChromebook />
        </div>
        <div v-if="hasPcOptimizer" class="mb-double">
          <WebrootPcOptimizer />
        </div>
        <div v-if="hasVpn" class="mb-double">
          <WebrootSecureVPN />
        </div>
        <div v-if="hasWebrootSunsetProducts" class="mb-double">
          <WebrootSunsetProducts />
        </div>
      </div>
      <Receipt class="mb" />
      <AccountCreditEarned :adjustments="buyFlowStore.cart?.adjustments || []" />
    </article>
    <LoadingBoxComponent
      :show-dialog="buyFlowStore.showProcessingOrderMessage || false"
      :message="t('Common.loading')"
    />
  </div>
</template>
<script setup lang="ts">
//Components
import Receipt from "./Receipt.vue";
import BuyProgressbar from "../BuyProgressbar.vue";
import AccountCreditEarned from "@/components/Buy/AccountCreditEarned.vue";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import LoadingBoxComponent from "@/components/shared/LoadingBox.vue";
import { changePageState } from "@/components/Buy/BuyHelper";
import { openTextBrands, pageStates as buyFlowPageStates } from "@/components/Buy/BuyEnums";
import CarboniteProvisional from "@/components/Buy/OrderConfirmation/CarboniteProvisional.vue";
import CarboniteRenewUpgrade from "@/components/Buy/OrderConfirmation/CarboniteRenewUpgrade.vue";
import CarboniteEstablishedAccount from "@/components/Buy/OrderConfirmation/CarboniteEstablishedAccount.vue";
//Others
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { useRouter } from "vue-router";
import { computed, onBeforeMount } from "vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { useNotificationsStore } from "@/stores/notifications";
import { useUserStore } from "@/stores/user";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import { FLAGS, WEBROOT_CHROMEBOOK, WEBROOT_PC_OPTIMIZER, WEBROOT_SECURE_VPN } from "@/define";
import WebrootThanks from "@/components/Buy/OrderConfirmation/WebrootThanks.vue";
import WebrootNextStepsEstablished from "@/components/Buy/OrderConfirmation/WebrootNextStepsEstablished.vue";
import WebrootNextStepsProvisional from "@/components/Buy/OrderConfirmation/WebrootNextStepsProvisional.vue";
import WebrootNextStepsAllstate from "@/components/Buy/OrderConfirmation/WebrootNextStepsAllstate.vue";
import WebrootChromebook from "@/components/Buy/OrderConfirmation/DownloadCards/WebrootChromebook.vue";
import WebrootSecureVPN from "@/components/Buy/OrderConfirmation/DownloadCards/WebrootSecureVPN.vue";
import WebrootAllOthers from "@/components/Buy/OrderConfirmation/DownloadCards/WebrootAllOthers.vue";
import { webrootOnlyAllstateSkus, webrootSkusThatAreRenewOnly } from "@/common/webrootProductName";
import WebrootSunsetProducts from "@/components/Buy/OrderConfirmation/DownloadCards/WebrootSunsetProducts.vue";
import WebrootPcOptimizer from "@/components/Buy/OrderConfirmation/DownloadCards/WebrootPcOptimizer.vue";
import WebrootNextStepsRenewUpgrade from "@/components/Buy/OrderConfirmation/WebrootNextStepsRenewUpgrade.vue";

const userStore = useUserStore();
const siteSettingsStore = useSiteSettingsStore();
const componentName = "OrderConfirmation";

logEvent("created", componentName);
const router = useRouter();
const buyFlowStore = useBuyFlowStore();
const hasStandardWebroot = computed(() => {
  if (buyFlowStore.hasOnlyAllStateInCart) {
    return false;
  }
  if (buyFlowStore.hasOnlyCDLPinCart) {
    return false;
  }
  if (buyFlowStore.hasOnlyPcOptimizer) {
    return false;
  }

  //Remove Chromebook, VPN, non-renewable skus, allstate skus and pc optimizer
  const filteredItems = buyFlowStore?.cart?.items.filter(
    l =>
      l.sku !== WEBROOT_CHROMEBOOK &&
      l.sku !== WEBROOT_SECURE_VPN &&
      !webrootSkusThatAreRenewOnly.some(i => i === l.sku) &&
      !webrootOnlyAllstateSkus.some(i => i === l.sku) &&
      l.sku !== WEBROOT_PC_OPTIMIZER
  );

  return filteredItems && filteredItems.length > 0;
});

const hasChromeBook = computed(() => {
  return buyFlowStore?.cart?.items.some(item => item.sku === WEBROOT_CHROMEBOOK);
});

const hasPcOptimizer = computed(() => {
  return buyFlowStore?.cart?.items.some(item => item.sku === WEBROOT_PC_OPTIMIZER);
});

const hasVpn = computed(() => {
  return buyFlowStore?.cart?.items.some(item => item.sku === WEBROOT_SECURE_VPN);
});

const hasWebrootSunsetProducts = computed(() => {
  return buyFlowStore?.cart?.items.some(item => webrootSkusThatAreRenewOnly.includes(item.sku));
});

onBeforeMount(() => {
  logEvent("beforeMount", componentName);

  /* setting pageState so progressbar updates correctly */
  changePageState(buyFlowPageStates.orderConfirmation);
  onBrowserBack();

  //Set the security questions to be skipped here to avoid a user
  //typing in the URL instead of clicking the continue to account btn
  if (!userStore.isEstablishedAccount) {
    siteSettingsStore.setSkipAccountSetup(true);
    siteSettingsStore.redirectPreliminaryUserToBackup = true;
  }
});

function onBrowserBack() {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = () => {
    const notificationsStore = useNotificationsStore();
    notificationsStore.addNotification({ type: "PurchaseDoneContinueToMyAccountPage" });
    router.go(1);
  };
}
</script>
