<template>
  <ul v-if="props.isUnsupportedOs" class="form">
    <li class="danger-text">
      {{ t(`${parentComponentName}.${productCode}Unsupported`) }}
    </li>
    <li>
      <div>{{ t(`${parentComponentName}.${productCode}UnsupportedReference`) }}</div>
    </li>
  </ul>
  <ol v-else class="form">
    <li>
      <i18n-t :keypath="parentComponentName + '.' + productCode + 'StepOne'" tag="p" scope="global">
        <template #copyLink>
          <button class="inline-btn-link" target="_blank" @click="copyCurrentURL()">
            {{ t(`${parentComponentName}.copyLink`) }}
          </button>
        </template>
      </i18n-t>
    </li>
    <li>
      <p>{{ t(`${parentComponentName}.${productCode}StepTwo`) }}</p>
      <!-- Temp: To be refined by Carl -->
      <button class="inline-btn-link" @click="download()">
        <svg-icon-component icon="logo-google-play-store-en" style="width: 135px; height: 40px; fill: black" />
      </button>
      <!-- -->
    </li>
    <li>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="t(`${parentComponentName}.${productCode}StepThree`)"></p>
      <Keycodes :keycodes="keycodes" />
    </li>
  </ol>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscriberDownloadsInfo } from "@/common/api/unifiedPortal/interfaces";
import { productList } from "@/common/productList";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { webrootLicenseCategories } from "@/common/webrootProductName";
import { copyCurrentURL } from "@/common/copyCurrentURL";
import Keycodes from "@/components/Downloads/Keycodes.vue";

const productCode = "WSCB";
const componentName = `${productCode}ProductComponent`;
const parentComponentName = "DownloadProductComponent";

const props = defineProps({
  isUnsupportedOs: {
    type: Boolean,
    default: null,
  },
  keycodes: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
});

logEvent("created", componentName, props);

function download() {
  const link = productList().find(c => c.code === webrootLicenseCategories.WSCB)?.targetSystem[0].link;
  window.open(link, "_blank");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
