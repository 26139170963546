import { useSubscriptionsStore } from "@/stores/subscriptions";
import {
  premiumLCNs,
  addOnLCNs,
  nonAllstateWebrootLCNs,
  allstateLCNs,
  allstatePremiumFamilyLCNs,
  webrootLicenseCategories,
} from "@/common/webrootProductName";
import { useAllstateSubscriptionsStore } from "@/stores/allstateSubscriptions";
import {
  SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY,
  SUBSCRIPTION_LAST_ALLSTATE_DAY,
  SUBSCRIPTION_MIN_ACTIVE_DAY,
  SUBSCRIPTION_MIN_EXPIRED_DAY,
} from "@/define";
import { useNotificationsStore } from "@/stores/notifications";
import { unifiedApi } from "@/common";
import { getUrl } from "@/common/getUrl";

async function getSubscriptions() {
  const subscriptionsStore = useSubscriptionsStore();
  await subscriptionsStore.populateSubscriptions();
  const subscriptions = subscriptionsStore.subscriptions;
  return subscriptions;
}

async function getAllstateSubscriptions() {
  const allstateSubscriptionsStore = useAllstateSubscriptionsStore();
  const subscriptions = (await allstateSubscriptionsStore.getAllstateSubscriptions()).allstateSubscriptions;
  return subscriptions;
}

// Returns if the user has non-Allstate Webroot subscriptions
export async function hasWebrootSubscriptions() {
  const subscriptions = await getSubscriptions();
  const response =
    subscriptions &&
    subscriptions.some(
      l =>
        nonAllstateWebrootLCNs.includes(l.licenseCategoryName ?? "") && l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY
    );
  return response;
}

export async function hasActivePremiumSubscriptions() {
  const subscriptions = await getSubscriptions();
  const response =
    subscriptions &&
    subscriptions.some(
      l =>
        premiumLCNs.includes(l.licenseCategoryName ?? "") &&
        l.remainingDays > 0 &&
        l.webrootStatusDescription.toLowerCase() === "active"
    );
  return response;
}

export async function hasActivePremiumFamilySubscriptions() {
  const subscriptions = await getSubscriptions();
  const response =
    subscriptions &&
    subscriptions.some(
      l =>
        allstatePremiumFamilyLCNs.includes(l.licenseCategoryName ?? "") &&
        l.remainingDays > 0 &&
        l.webrootStatusDescription.toLowerCase() === "active"
    );
  return response;
}

export async function hasInactivePremiumSubscriptions() {
  const subscriptions = await getSubscriptions();
  const response =
    subscriptions &&
    subscriptions.some(
      l => premiumLCNs.includes(l.licenseCategoryName ?? "") && l.webrootStatusDescription.toLowerCase() === "inactive"
    );
  return response;
}

export async function hasExpiredPremiumSubscriptions() {
  const subscriptions = await getSubscriptions();
  const response =
    subscriptions &&
    subscriptions.some(
      l =>
        premiumLCNs.includes(l.licenseCategoryName ?? "") &&
        l.remainingDays < 0 &&
        l.remainingDays >= SUBSCRIPTION_LAST_ALLSTATE_DAY
    );
  return response;
}

export async function hasAddOnSubscriptions() {
  const subscriptions = await getSubscriptions();
  const response =
    subscriptions &&
    subscriptions.some(
      l => addOnLCNs.includes(l.licenseCategoryName ?? "") && l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY
    );
  return response;
}

export async function hasAllstateSubscriptions() {
  const subscriptions = await getSubscriptions();
  const response =
    subscriptions &&
    subscriptions.some(
      l => allstateLCNs.includes(l.licenseCategoryName ?? "") && l.remainingDays > SUBSCRIPTION_LAST_ALLSTATE_DAY
    );
  return response;
}

export async function hasAllstateAccountSetup() {
  const allstateSubscriptions = await getAllstateSubscriptions();
  const response = allstateSubscriptions && allstateSubscriptions.some(a => a.canceledOnDate === null);
  return response;
}

export async function hasAllstateUpgradeNotification() {
  const allstateSubscriptions = await getAllstateSubscriptions();
  const response = allstateSubscriptions && allstateSubscriptions.some(a => a.canceledOnDate === null);
  return response;
}

export function dismissAllstateUpgradeNotification() {
  const notificationsStore = useNotificationsStore();
  notificationsStore.clearNotifications();
  unifiedApi.updateAllstateSubscriptions({ showUpgradedBanner: false });
  const allstateStore = useAllstateSubscriptionsStore();
  const allstateSubs = allstateStore.allstateSubscriptions;
  allstateSubs.forEach(l => {
    l.showUpgradedBanner = false;
  });
  allstateStore.allstateSubscriptions = allstateSubs;
}

// allstate upgrade banner displays if More than 90 days from sub expiring and api returns showUpgradedBanner and flag is set to show allstate family
export async function showAllstateBanner() {
  const notificationsStore = useNotificationsStore();
  const subscriptionsStore = useSubscriptionsStore();
  const allstateStore = useAllstateSubscriptionsStore();

  if (subscriptionsStore.subscriptions.length === 0) {
    await subscriptionsStore.populateSubscriptions();
  }
  const allstateSubscription = subscriptionsStore.subscriptions.find(l =>
    allstateLCNs.includes(l.licenseCategoryName ?? "")
  );
  if (
    allstateStore.allstateSubscriptions.some(l => l.showUpgradedBanner) &&
    allstateSubscription?.remainingDays &&
    allstateSubscription?.remainingDays > SUBSCRIPTION_MIN_ACTIVE_DAY
  ) {
    const lcn = allstateSubscription.licenseCategoryName;
    notificationsStore.clearNotifications();
    notificationsStore.addNotification({ type: "AllstateUpgradeInfo", params: { licenseCategoryName: lcn } });
  }
}

export async function getUpgradeCartUrl(keycode: string) {
  const url = (await unifiedApi.getWebrootRenewUrl(keycode)).data.redirectUrl;
  if (url) {
    const queryString = url.split("?")[1];
    // This URL will be only used here, so no need to add to env files.
    return `${getUrl("ALLSTATE_UPGRADE_CART")}?${queryString}`;
  }
}

export async function hasExpiredBestBuyAllState() {
  const subscriptions = await getSubscriptions();
  const allstateSubs = subscriptions.filter(s => allstateLCNs.includes(s.licenseCategoryName ?? ""));
  if (!allstateSubs || allstateSubs.length === 0) {
    return false;
  }

  const bestBuyAllStateSub = allstateSubs.find(l => l.licenseCategoryName === webrootLicenseCategories.WSAIA);
  //Use this check to make sure the user did not already buy a different AllState that would be the Active item
  const hasActiveNonBestBuyAllState = allstateSubs.find(l => l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY);

  if (!hasActiveNonBestBuyAllState && bestBuyAllStateSub) {
    //Sub is expired but not able to be recaptured
    return (
      bestBuyAllStateSub.remainingDays < SUBSCRIPTION_MIN_EXPIRED_DAY &&
      bestBuyAllStateSub.remainingDays > SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY
    );
  }

  return false;
}
