<template>
  <div v-if="showSpinner" class="payment-history">
    <LoadingSpinner />
  </div>
  <div v-else class="content downloads-page">
    <NotificationsComponent
      context="DownloadsComponent"
      @notification-open-primary-email-verification="toggleVerifyPrimaryEmailAddressModal(null)"
    />
    <section class="mb-double">
      <header>
        <h1>{{ tn("downloadsH1") }}</h1>
      </header>
      <div v-if="showInactiveKeycodeMessage">
        <ul class="form">
          <li>{{ tn("keycodesInactiveHeader") }}</li>
          <li>{{ tn("keycodesInactiveParagraph") }}</li>
        </ul>
        <ul class="disc">
          <li class="mb-quarter">{{ tn("keycodesInactivePoint1") }}</li>
          <li class="mb-quarter">
            <i18n-t keypath="DownloadsComponent.keycodesInactivePoint2" tag="span" scope="global">
              <template #subscriptions>
                <span>
                  <router-link :to="{ name: 'subscription_information' }">{{ tn("subscriptions") }}</router-link>
                </span>
              </template>
            </i18n-t>
          </li>
          <li class="mb-quarter">{{ tn("keycodesInactivePoint3") }}</li>
        </ul>
      </div>
      <ul class="available-downloads">
        <li v-if="productList.length === 0">{{ tn("noDownloads") }}</li>
        <div v-for="(products, subGroup) in groupedProducts" :key="subGroup">
          <h1 class="border-none pt-half">{{ tn(subGroup + "Header") }}</h1>
          <li v-for="product in products.filter(p => p.code != 'LPPM')" :key="product.code">
            <div class="mb-double flex-start-lg">
              <div class="flex-start-mobile-and-md align-items-center no-shrink">
                <div class="mr">
                  <img v-if="product.imageType === 'png'" :src="product.image" :alt="tn(product.code + 'Header')" />
                  <svg-icon-component v-else-if="product.imageType === 'svg'" :icon="product.image" />
                </div>
                <h3 class="mb-quarter only-show-mobile-and-md-inline">{{ tn(product.code + "Header") }}</h3>
              </div>
              <div class="grow pr-half">
                <h3 class="mb-quarter only-show-lg-inline">{{ tn(product.code + "Header") }}</h3>
                <p>{{ tn(product.code + "Paragraph") }}</p>
                <ul class="inline mb-half">
                  <!-- Target Systems -->
                  <li v-for="targetSystem in product.targetSystem" :key="targetSystem.familyName">
                    <svg-icon-component :icon="targetSystem.icon" class="icon-sm mr-quarter" />
                    <span v-if="targetSystem.versionKey">{{ tn(targetSystem.versionKey) }}</span>
                    <span v-else>{{ tn(targetSystem.familyName + "Versions") }}</span>
                  </li>
                </ul>
              </div>
              <div class="pt-and-a-half-desktop-lg">
                <button
                  :id="`btn${product.code}${product.buttonCaption}`"
                  type="button"
                  class="btn-primary"
                  @click="showDownloads(product.code)"
                >
                  {{ tn(product.buttonCaption) }}
                </button>
              </div>
            </div>
          </li>
          <LastPassDownloadsTabComponent
            v-if="products.some(p => p.code === 'LPPM')"
            @open-download-modal="showDownloads"
          />
        </div>
        <li v-if="showVerifyEmailMessage">
          <div class="info warning">{{ tn("verifyEmailMessage") }}</div>
          <div>
            <button
              id="btnVerifyEmailDialog"
              class="btn-primary mt"
              @click="toggleVerifyPrimaryEmailAddressModal('open')"
            >
              {{ tn("btnVerifyEmail") }}
            </button>
          </div>
        </li>
      </ul>
    </section>
    <section class="keycodes mb-double">
      <header>
        <h1>{{ tn("keycodesH1") }}</h1>
      </header>

      <div class="opposite-ends mb">
        <div>
          <strong>{{ tn("keycodesInfo") }}</strong>
        </div>
        <div v-if="showKeycodesText && showKeycodeToggleButton">
          <button id="hide" class="inline-btn-link" type="button" @click="showHideKeycodesInfo">
            {{ showKeycodesInfo ? tn("hide") : tn("show") }}
          </button>
        </div>
      </div>

      <ul v-if="keycodesInfo && showKeycodesInfo" class="keycode-list">
        <li
          v-for="(n, i) in keycodesInfo.webrootSubscriptions.filter(
            w => w.licenseCategoryName !== webrootLicenseCategories.WIFI
          )"
          :id="`webrootSubscriptionsInfo${i}`"
          :key="n.keycode"
          class="space-between mb"
        >
          <div v-for="kc in splitStringByComma(n.keycode)" :key="kc" class="mb-0">
            <div class="flex-start-md mb-quarter">
              {{ getLicenseDescription(n.licenseCategoryName) }}
              <p v-if="!isKeycodeInactive(n.licenseStatusDescription)" class="pl-quarter">
                <small v-if="isKeycodeExpired(n, true)" class="danger-text">{{ tn("Expired") }}</small>
                <small v-else class="text-grey">({{ tn("expires") }} {{ getFormattedDate(n.expirationDate) }})</small>
              </p>
            </div>
            <p class="keycode-detail mb-half">
              <strong class="mr-half">{{ formatKeycode(kc) }}</strong>
              <button
                v-if="!isKeycodeExpired(n, true) || isKeycodeInactive(n.licenseStatusDescription)"
                :id="`copy${kc}`"
                class="inline-btn-link"
                type="button"
                @click="copy(formatKeycode(kc))"
              >
                {{ t("Common.copy") }}
              </button>
            </p>
          </div>
        </li>
      </ul>
      <p v-if="showKeycodesText">
        <strong>{{ tn("keycodesInfoParagraph") }}</strong>
      </p>
      <div v-if="hasValidatedEmail">
        <button
          id="btnAddKeycode"
          type="button"
          class="btn-outline-primary"
          @click="toggleAddKeycodeModal('valid_click')"
        >
          {{ tn("btnAddKeycode") }}
        </button>
      </div>
      <div v-else>
        <p class="notSetUp">{{ tn("validateEmailWarning") }}</p>
        <button
          id="btnAddKeycode"
          type="button"
          class="btn-outline-primary"
          @click="toggleAddKeycodeModal('invalid_click')"
        >
          {{ tn("btnAddKeycode") }}
        </button>
      </div>
    </section>
    <section
      v-if="showAdditionalSecurity && canDisplayGlobalFeature(globalFeatures.exploreSecurityOptions)"
      class="explore mb"
    >
      <header>
        <h1>{{ tn("additionalSecurityH1") }}</h1>
      </header>
      <ul>
        <li v-if="!isResellerManaged" class="mb flex-start-lg">
          <div class="flex-start-mobile-and-md align-items-center no-shrink">
            <div class="mr">
              <img :src="carbSafePng" :alt="tn('SAFEHeader')" />
            </div>
            <h3 class="mb-quarter only-show-mobile-and-md-inline">{{ tn("SAFEHeader") }}</h3>
          </div>
          <div class="grow">
            <h3 class="mb-quarter only-show-lg-inline">{{ tn("SAFEHeader") }}</h3>
            <p>{{ tn("safeCloudBackupParagraph") }}</p>
            <p>
              <a :href="getUrl('SAFE_LEARN_MORE') || ''" target="_blank" rel="noopener noreferrer">
                {{ tn("learnMore") }}
              </a>
            </p>
          </div>
        </li>
        <li class="mb flex-start-lg">
          <div class="flex-start-mobile-and-md align-items-center no-shrink">
            <div class="mr">
              <svg-icon-component icon="webroot-internet-security" />
            </div>
            <h3 class="mb-quarter only-show-mobile-and-md-inline">{{ tn("WSAVHeader") }}</h3>
          </div>
          <div class="grow">
            <h3 class="mb-quarter only-show-lg-inline">{{ tn("WSAVHeader") }}</h3>
            <p>{{ tn("exploreSecureAnywhereParagraph") }}</p>
            <p>
              <a :href="getUrl('WEBROOT_LEARN_MORE') || ''" target="_blank" rel="noopener noreferrer">
                {{ tn("learnMore") }}
              </a>
            </p>
          </div>
        </li>
      </ul>
    </section>
    <VerifyPrimaryEmailAddressComponent
      v-if="showVerifyPrimaryEmailAddressModal"
      :show-dialog="showVerifyPrimaryEmailAddressModal"
      :email="email"
      @close-modal="toggleVerifyPrimaryEmailAddressModal"
    />
    <VerifyPrimaryEmailAddressCtnComponent
      v-if="showVerifyEmailAddressCtnModal"
      :email="emailInfo.email"
      :show-dialog="showVerifyEmailAddressCtnModal"
      @close-modal="toggleVerifyEmailAddressCtnModal"
      @save-modal="toggleAddKeycodeModal"
    />
    <AddKeycodeComponent
      :show-dialog="showAddKeycodeModal"
      :keycodes-info="keycodesInfo"
      @close-modal="closeAddKeycodeModal"
      @save-modal="saveAddKeycodeModal"
    />
    <DownloadProductComponent
      :show-dialog="showDownloadProductModal"
      :product-name="selectedProduct"
      :keycodes="keycodesInfo"
      :install-type="typeOfInstall"
      @close-modal="toggleDownloadProductModal"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";

import { unifiedApi } from "@/common";
import { IAddKeycodeResponse, ISubscriberDownloadsInfo, IVerifyEmail } from "@/common/api/unifiedPortal/interfaces";

import { useUserStore } from "@/stores/user";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import { useNotificationsStore } from "@/stores/notifications";
import { useSubscriptionsStore } from "@/stores/subscriptions";

import { FLAGS } from "@/define";
import { hasBestBuyS2, hasAnyBestBuySubscriptions } from "@/common/bestBuy";
import { formatDateString } from "@/common/dateFormat";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { displayPasswordResetSuccess } from "@/common/passwordReset";
import { getWebrootLicenseDescription, webrootLicenseCategories, addOnLCNs } from "@/common/webrootProductName";
import { productList as getProductList, IProduct } from "@/common/productList";
import { formatKeycode as formatWebrootKeycode } from "@/common/formatKeycode";
import { isKeycodeExpired } from "@/components/Downloads/isKeycodeExpired";

import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import VerifyPrimaryEmailAddressComponent from "@/components/shared/dialogs/VerifyPrimaryEmail/VerifyPrimaryEmailAddress.vue";
import VerifyPrimaryEmailAddressCtnComponent from "@/components/Downloads/VerifyPrimaryEmailAddressCtn.vue";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import AddKeycodeComponent from "@/components/Downloads/AddKeycode.vue";
import DownloadProductComponent from "@/components/shared/dialogs/DownloadProduct/DownloadProduct.vue";

import carbSafePng from "@/assets/img/carbSafe.png";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { clearRefreshFunctions } from "@/components/shared/LanguageSelector/LanguageSelector";
import LastPassDownloadsTabComponent from "./LastPassDownloadsTab.vue";
import { getUrl } from "@/common/getUrl";
import { canDisplayGlobalFeature, globalFeatures } from "@/common/featureDisplayAccess";
import { showAllstateBanner } from "@/components/Allstate/AllstateHelper";
import { productSubGroups } from "@/globalEnums";

const name = "DownloadsComponent";

const props = defineProps({
  errorMessage: {
    type: String,
    required: false,
    default: null,
  },
  keycode: {
    type: String,
    required: false,
    default: null,
  },
});
logEvent("created", name);
const userStore = useUserStore();
const notificationsStore = useNotificationsStore();
const subscriptionsStore = useSubscriptionsStore();

const keycodesInfo = ref<ISubscriberDownloadsInfo>();
const email = ref<string>("");
const showVerifyPrimaryEmailAddressModal = ref(false);
const showKeycodesText = ref(false);
const showSpinner = ref(true);
const productList = ref<IProduct[]>([]);
const groupedProducts = ref<Record<string, IProduct[]>>({});
const showKeycodesInfo = ref(true);
const showKeycodeToggleButton = ref(false);
const showInactiveKeycodeMessage = ref(false);
const showAddKeycodeModal = ref<boolean>(false);
const showDownloadProductModal = ref<boolean>(false);
const selectedProduct = ref<string>("SAFE");
const typeOfInstall = ref<string>("");
const siteSettings = useSiteSettingsStore();
const showAdditionalSecurity = ref<boolean>(true);
const emailInfo = ref<IVerifyEmail>({ email: "", response: "" });
const showVerifyEmailAddressCtnModal = ref(false);
const isResellerManaged = ref<boolean>(true);
const hasBestBuy = ref(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

function toggleDownloadProductModal(type: string | null) {
  type = type || "modal";
  logEvent("toggleDownloadProductModal", name, `${showDownloadProductModal.value ? "Hiding" : "Showing"} via ${type}`);
  showDownloadProductModal.value = !showDownloadProductModal.value;
}

async function toggleAddKeycodeModal(type: string | null) {
  if (!hasValidatedEmail.value) {
    try {
      // Sending the email to the primary email for verification for first-time
      const emailUpdateResponse = (await unifiedApi.resendVerificationEmail()).data;
      if (emailUpdateResponse !== "") {
        logEvent("resend another email failed : ", emailUpdateResponse);
      }
    } catch (error) {
      logEvent(`Resulted in not OK ${error}`, `${name}/sendAnotherEmail`);
      handleApiError(error as AxiosError);
    }
    toggleVerifyEmailAddressCtnModal("open");
    return;
  }
  if (type === "verificationCompleted") {
    toggleVerifyEmailAddressCtnModal("close");
  }
  type = type || "modal";
  logEvent("toggleAddKeycodeModal", name, `${showAddKeycodeModal.value ? "Hiding" : "Showing"} via ${type}`);
  showAddKeycodeModal.value = !showAddKeycodeModal.value;
}

async function toggleVerifyPrimaryEmailAddressModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleVerifyPrimaryEmailAddressModal",
    name,
    `${showVerifyPrimaryEmailAddressModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  showVerifyPrimaryEmailAddressModal.value = !showVerifyPrimaryEmailAddressModal.value;
  if (!showVerifyPrimaryEmailAddressModal.value) {
    notificationsStore.clearNotifications();
    await reloadSubscriberInfo();
    const subscriberInfo = userStore.currentUser?.subscriberInfo;
    if (subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail) {
      notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
    } else {
      notificationsStore.deleteNotificationByType("PrimaryEmailVerificationWebroot");
    }
    await getSubscriberDownloads();
  }
}

function toggleVerifyEmailAddressCtnModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleVerifyEmailAddressCtnModal",
    name,
    `${showVerifyEmailAddressCtnModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  showVerifyEmailAddressCtnModal.value = !showVerifyEmailAddressCtnModal.value;
}

const user = computed(() => {
  return userStore.currentUser;
});

const hasValidatedEmail = computed(() => {
  return FLAGS.ENABLE_EMAIL_VALIDATION_KEYCODE == "false" || user.value?.subscriberInfo?.hasValidatedEmail;
});

const showVerifyEmailMessage = computed(() => {
  return !!user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail;
});

const router = useRouter();

// life cycle hooks
onMounted(async () => {
  try {
    logEvent("mounted", name, user.value);
    email.value = user.value?.email ?? "";
    emailInfo.value.email = user.value?.email ?? "";
    notificationsStore.clearNotifications();
    const hasPendingWebrootLicenses = user.value?.subscriberInfo?.hasPendingWebrootLicenses;
    if (showVerifyEmailMessage.value) {
      notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
    }
    displayPasswordResetSuccess();
    if (siteSettings.getIsCreateAccountFlow()) {
      if (props.errorMessage) {
        if (props.errorMessage == "NotFoundError_NoData") {
          notificationsStore.addNotification({
            type: "ValidationError_FraudKeycode",
            params: { url: "subscription_information", text: "NotificationsComponent.RenewHere" },
          });
        }
        if (props.errorMessage === "ValidationError_FraudKeycode") {
          //The keycode has been marked as license_distribution_method_id=919 meaning it has been labelled as Fraudulent.
          notificationsStore.addNotification({
            type: "ValidationError_FraudKeycode",
            params: { url: "support", text: "NotificationsComponent.support", keycode: props.keycode },
          });
        } else if (props.errorMessage === "ValidationError_LicenseStatusIneligible") {
          //The keycode that has status disabled will give this error state.
          notificationsStore.addNotification({
            type: "ValidationError_LicenseStatusIneligible",
            params: { url: "support", text: "NotificationsComponent.support", keycode: props.keycode },
          });
        } else if (props.errorMessage === "ValidationError_KeycodeEmailMismatch") {
          //The account_email provided in the request data does not match up with the keycode's customer_email in the Ecom database.
          notificationsStore.addNotification({
            type: "ValidationError_KeycodeEmailMismatch",
            params: { text: props.keycode },
          });
        } else if (props.errorMessage === "ValidationError_KeycodeIneligible") {
          //The keycode in the request data was valid, but identified as product_line_id=300 meaning the keycode is for an Enterprise product and not Consumer.
          notificationsStore.addNotification({
            type: "ValidationError_KeycodeIneligible",
            params: { text: props.keycode },
          });
        } else if (props.errorMessage === "ValidationError_InvalidKeycode") {
          //The keycode in the request data was not the correct format (20 alphanumeric digits, dashes optional between every 4 digits).  Or the keycode in the request was the correct format but not a valid keycode in the Ecom database in this environment.
          notificationsStore.addNotification({
            type: "ValidationError_InvalidKeycode",
            params: { text: props.keycode },
          });
        } else if (props.errorMessage === "ValidationError_LicenseInfoNotFound") {
          notificationsStore.addNotification({
            type: "ValidationError_InvalidKeycode",
            params: { text: props.keycode },
          });
        } else if (props.errorMessage === "ValidationError_LicenseInfoAlreadyLinked") {
          notificationsStore.addNotification({
            type: "ValidationError_KeycodeEmailMismatch",
            params: { text: props.keycode },
          });
        } else {
          notificationsStore.addNotification({
            type: "ValidationError_InvalidKeycode",
            params: { text: props.keycode },
          });
        }
      } else if (userStore.isCreateAccountKeycodeExpired && !hasPendingWebrootLicenses) {
        logEvent("onMounted:KeycodeExpired", `${userStore.isCreateAccountKeycodeExpired}`);
        notificationsStore.addNotification({
          type: "KeycodeExpired",
          params: { url: "subscription_information", text: "NotificationsComponent.RenewHere" },
        });
      } else if (userStore.createAccountEmailMismatchKeycode) {
        notificationsStore.addNotification({
          type: "ValidationError_KeycodeEmailMismatch",
          params: { text: userStore.createAccountEmailMismatchKeycode },
        });
      }

      //if email is not validated and not showed the verify email dialog yet
      if (!hasValidatedEmail.value && !siteSettings.getShowedVerifyEmailDialogAfterCreateAccountFlow()) {
        //set the flag so that the verify email dialog must not be shown more than once
        siteSettings.setShowedVerifyEmailDialogAfterCreateAccountFlow(true);
        //The email sent functionality is moved to the dialog onMount;Now every time dialog is show an email is sent
        toggleVerifyPrimaryEmailAddressModal("open"); //show verify email dialog only once after create Account flow
      }
    }

    if (userStore.twoFactorAuthAddedFromFirstLogin) {
      notificationsStore.addNotification({ type: "SetTwoStepVerificationSuccess" });
      userStore.twoFactorAuthAddedFromFirstLogin = false;
    }
    await getSubscriberDownloads();
    // allstate upgrade banner displays if More than 90 days from sub expiring and api returns showUpgradedBanner and flag is set to show allstate family
    await showAllstateBanner();
    hasBestBuy.value = await hasAnyBestBuySubscriptions();
  } catch (err) {
    logException(err as Error, productList.value[0]);
  }
});

onUnmounted(() => {
  clearRefreshFunctions();
});

function getFormattedDate(date: string): string {
  return formatDateString(date);
}

function getLicenseDescription(license: string): string | undefined {
  return getWebrootLicenseDescription(license);
}

function formatKeycode(keycode: string): string {
  return formatWebrootKeycode(keycode);
}

function isKeycodeInactive(status: string): boolean {
  return status === "Inactive";
}

function copy(keycodeText: string) {
  logEvent("copy keycode", name, keycodeText);
  navigator.clipboard.writeText(keycodeText);
}

function splitStringByComma(license: string) {
  return license.split(",");
}

async function getSubscriberDownloads() {
  try {
    if (user.value) {
      productList.value = [];
      keycodesInfo.value = (await unifiedApi.getSubscriberDownloadsInfo()).data;

      if (keycodesInfo.value) {
        // remove Allstate Add-On
        keycodesInfo.value.webrootSubscriptions = keycodesInfo.value.webrootSubscriptions.filter(
          k => !addOnLCNs.includes(k.licenseCategoryName ?? "")
        );

        //convert the expiration dates to ticks and store with the objects
        for (const s of keycodesInfo.value.webrootSubscriptions) {
          s.ticks = new Date(s.expirationDate).getTime();
        }
        //sort on ticks
        keycodesInfo.value.webrootSubscriptions.sort((sub1, sub2) => (sub1.ticks ?? 0) - (sub2.ticks ?? 0));

        if (keycodesInfo.value.hasFailedWebrootProvisioning && keycodesInfo.value.webrootSubscriptions.length < 1) {
          notificationsStore.addNotification({
            type: "RetrieveKeycodesFailed",
            params: { url: "support", text: "Common.contactSupport" },
          });
        }
        if (keycodesInfo.value.hasFailedWebrootProvisioning && keycodesInfo.value.webrootSubscriptions.length > 0) {
          notificationsStore.addNotification({ type: "PartialRetrieveKeycodesFailed" });
        }
        showKeycodesText.value = keycodesInfo.value.webrootSubscriptions.length > 0;
        showInactiveKeycodeMessage.value =
          !keycodesInfo.value.webrootSubscriptions.some(
            sub => !isKeycodeExpired(sub) || isKeycodeInactive(sub.licenseStatusDescription)
          ) && !keycodesInfo.value.hasSafeSubscriptions;
        keycodesInfo.value.isCarboniteOnlyCustomer = keycodesInfo.value?.webrootSubscriptions.find(
          sub => sub.distributionMethodCode !== "CARB"
        )
          ? false
          : true;
        if (keycodesInfo.value.webrootSubscriptions.length > 1) showKeycodeToggleButton.value = true;
      }
      const hasResellerComputer = user.value.subscriberInfo?.hasResellerComputer ?? false;
      const isPartnerReferral = user.value.subscriberInfo?.isPartnerReferral ?? false;
      isResellerManaged.value = hasResellerComputer && !isPartnerReferral;
    }

    const products = getProductList().filter(p => showProduct(p) && canDisplayGlobalFeature(p.featureName)) || [];
    productList.value = products.sort((a, b) => {
      const sortOrderA = a.sortOrder;
      const sortOrderB = b.sortOrder;
      return sortOrderA < sortOrderB ? -1 : sortOrderA > sortOrderB ? 1 : 0;
    });
    groupedProducts.value = groupBySubGroup();
    //ZUOR-49396: Filter out the WIFI product after the product list is created so we don't lose the ability to get the app
    if (keycodesInfo.value) {
      keycodesInfo.value.webrootSubscriptions = keycodesInfo.value.webrootSubscriptions.filter(
        k => k.licenseCategoryName !== webrootLicenseCategories.WIFI
      );
    }

    //if user has any best buy that is not expired yet (more than 30 days) ,don't show additional security section
    //rest all cases addition security section should be visible
    const bestBuyS2 = await hasBestBuyS2();
    logEvent(`hasBestBuyS2:${bestBuyS2}`, name);
    showAdditionalSecurity.value = !bestBuyS2;
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, `${name}/getSubscriberDownloadsInfo`);
    handleApiError(error as AxiosError);
  }
  showSpinner.value = false;
}

function groupBySubGroup() {
  var groups: Record<string, IProduct[]> = {};
  productList.value.forEach(product => {
    const subGroup = productSubGroups[product.subGroup];
    if (!groups[subGroup]) {
      groups[subGroup] = [];
    }
    groups[subGroup].push(product);
  });

  // Sort the groups by the enum values
  const sortedGroups = Object.keys(groups)
    .sort(
      (a, b) =>
        productSubGroups[a as keyof typeof productSubGroups] - productSubGroups[b as keyof typeof productSubGroups]
    )
    .reduce((sorted: Record<string, IProduct[]>, key) => {
      sorted[key] = groups[key];
      return sorted;
    }, {});

  return sortedGroups;
}

async function saveAddKeycodeModal(response: IAddKeycodeResponse) {
  logEvent("add keycode", name, response);

  notificationsStore.clearNotifications();
  if (response.messageId === "KeycodeExpired") {
    notificationsStore.addNotification({
      type: response.messageId,
      params: { url: "subscription_information", text: "NotificationsComponent.RenewHere" },
    });
  } else {
    await reloadSubscriberInfo();
    await subscriptionsStore.forceRefresh();
    notificationsStore.addNotification({ type: "AddKeycodeSuccess", params: { text: response.keycode } });
  }
  getSubscriberDownloads();
  toggleAddKeycodeModal("save");
}

function closeAddKeycodeModal(response: IAddKeycodeResponse | null) {
  if (response?.messageId === "KeycodeAlreadyExists" || response?.messageId === "ValidationError_AMZSLicense") {
    logEvent("closeAddKeycodeModal", name, response);
    notificationsStore.addNotification({ type: response.messageId, params: { text: response.keycode } });
  }
  toggleAddKeycodeModal("close");
}

function showProduct(product: IProduct): boolean {
  // Disable chromebook display if flag is turned off
  if (FLAGS.ENABLE_CHROMEBOOK === "false" && product.code === webrootLicenseCategories.WSCB) return false;
  // Disable VPN display if flag is turned off
  if (FLAGS.ENABLE_VPN === "false" && product.code === webrootLicenseCategories.WIFI) return false;

  const licenseCategories = product.licenseCategories;
  if (keycodesInfo.value) {
    logEvent("showProduct", name, product);

    // For any Webroot Complete Retail, show Safe Backup Product
    const hasWsacRetail = user.value?.subscriberInfo?.hasWsacRetail ?? false;
    if ((keycodesInfo.value.hasSafeSubscriptions || hasWsacRetail) && licenseCategories.find(c => c === "SAFE")) {
      return true;
    }

    for (const category of licenseCategories) {
      const kc = keycodesInfo.value?.webrootSubscriptions.find(
        e =>
          e.licenseCategoryName === category && (!isKeycodeExpired(e) || isKeycodeInactive(e.licenseStatusDescription))
      );
      if (kc) {
        return true;
      }
    }
  }
  return false;
}

function showDownloads(product: string) {
  logEvent("showDownloads", name, product);
  if (product === "SAFE") {
    router.push("/backup");
  } else {
    selectedProduct.value = product;
    typeOfInstall.value = "Install";
    toggleDownloadProductModal("showDownloads");
  }
}

function showHideKeycodesInfo() {
  showKeycodesInfo.value = !showKeycodesInfo.value;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
.notSetUp {
  color: var(--dark-grey);
  font-style: italic;
  margin-top: var(--space-half);
}
</style>
